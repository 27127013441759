import React, { useEffect, useId, useState } from 'react';
import { Input } from '@/UI';
import classNames from 'classnames';
import { InputProps } from 'antd/es/input';
import { CloseIcon, EyeIcon, EyeInvisibleIcon } from '@/components/icons';
import { motion } from 'framer-motion';

interface IProps extends InputProps {
  bordered?: boolean;
  type?: 'text' | 'password';
  allowClear?: boolean;
  required?: boolean;
}

const CustomInput = ({
  placeholder,
  required,
  className = '',
  bordered = true,
  value,
  onChange,
  type = 'text',
  allowClear = false,
  ...props
}: IProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);
  const [isAutoFilled, setIsAutoFilled] = useState(false);
  const id = useId();

  useEffect(() => {
    if (!id) return;

    const inputElement = document.getElementById(id) as any;
    if (!inputElement) return;

    // Add animation event listener to detect autofill
    const handleAnimation = (e) => {
      if (e.animationName === 'on-auto-fill-start') {
        setIsAutoFilled(true);
      }
    };

    inputElement.addEventListener('animationstart', handleAnimation);

    return () => {
      inputElement.removeEventListener('animationstart', handleAnimation);
    };
  }, [id, onChange, inputValue]);

  const handleChanged = (e) => {
    if (isAutoFilled) setIsAutoFilled(false);
    setInputValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleClear = () => {
    if (isAutoFilled) setIsAutoFilled(false);
    setInputValue('');
    onChange?.(undefined);
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  useEffect(() => {
    if (value === undefined) return;
    setInputValue(value);
  }, [value]);

  return (
    <div
      className={`custom-input relative flex flex-col w-full bg-th-background rounded-md group ${className}`}
    >
      <motion.label
        htmlFor={id}
        initial={false}
        animate={{
          y: inputValue || isAutoFilled ? -20 : '-50%',
          fontSize: inputValue || isAutoFilled ? '0.75rem' : '1rem',
        }}
        transition={{ duration: 0.1 }}
        className="z-10 text-xs text-th-gray-700 absolute top-1/2 left-3 pointer-events-none"
      >
        {placeholder} {required && <span className="inline-block text-th-red-500">*</span>}
      </motion.label>

      <Input
        {...props}
        id={id}
        type={showPassword ? 'text' : type}
        value={inputValue}
        onChange={handleChanged}
        allowClear={false}
        className={classNames('flex-1 min-h-[56px]', {
          'border-none focus:border-th-primary': !bordered && !inputValue,
          'pt-6': inputValue,
        })}
      />

      {type === 'password' && (
        <div
          className="absolute z-10 right-3 my-auto h-full flex items-center justify-center text-th-gray-700 cursor-pointer hover:text-th-text"
          onClick={handleShowPassword}
        >
          {showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
        </div>
      )}
      {allowClear && inputValue && (
        <span
          className="absolute z-10 right-3 my-auto h-full hidden  group-hover:flex items-center justify-center text-th-gray-700 cursor-pointer hover:text-th-text"
          onClick={handleClear}
        >
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

export default CustomInput;
