import { LogoGamFull } from '@/components/icons';
import Link from 'next/link';
import React from 'react';

const LoginLayout = ({ content }) => {
  return (
    <div className="flex flex-col overflow-x-hidden overflow-y-auto h-screen full-screen-gradient">
      <span className="flex items-center cursor-pointer pt-10 mx-auto text-th-primary">
        <LogoGamFull />
      </span>
      <div className="flex flex-col items-center rounded-3xl flex-1 mx-auto max-w-[90vw] md:max-w-1/2 xl:px-12 px-6 xl:w-[668px] text-sm lg:text-base sm:w-[448px]">
        {content}
      </div>
      <div className="flex justify-center items-center gap-1 text-th-sub-text pb-8 text-xs">
        <Link
          className="text-th-primary hover:cursor-pointer font-medium hover:underline text-base"
          target="_blank"
          href={'https://gam.vn/terms-conditions-web'}
        >
          Điều khoản dịch vụ
        </Link>
        |
        <Link
          className="text-th-primary hover:cursor-pointer font-medium hover:underline text-base"
          target="_blank"
          href={'https://gam.vn/privacy-policy-web'}
        >
          Chính sách bảo mật
        </Link>
      </div>
    </div>
  );
};

export default LoginLayout;
